import { Injectable } from '@angular/core';
import { GeralApi } from '@core/api/ords';
import { GridVisao, GridVisoesData } from '@core/api/ords/model';
import { ColumnState, FilterModel } from 'ag-grid-community';
import { AgGridCommon } from 'ag-grid-community/dist/types/core/interfaces/iCommon';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

const FILTER_MODEL_MAX_LENGTH = 1500;

type SortState = Pick<ColumnState, 'colId' | 'sort' | 'sortIndex'>;

@Injectable({
  providedIn: 'root',
})
export class GridService {
  private visoes = new BehaviorSubject<Record<string, GridVisao[]>>({});
  private visoesLoaded = new BehaviorSubject<boolean>(false);
  private visaoSync = new BehaviorSubject<Record<string, GridVisao>>({});
  private lastVisao: GridVisao;

  constructor(private geralApi: GeralApi) {}

  getVisoesLoaded() {
    return this.visoesLoaded.asObservable();
  }

  fetchVisoes() {
    this.geralApi.fetchGridVisoes().subscribe(response => {
      const visoes: Record<string, GridVisao[]> = {};

      response.items.forEach(visao => {
        try {
          visao.state = JSON.parse(visao.cl_config_visao);
        } catch (e) {
          console.error('JSON inválido para visão: ', visao);
          visao.state = null;
        }
        const grade = visao.cd_grade;
        if (!visoes[grade]) {
          visoes[grade] = [];
        }
        visoes[grade].push(visao);
      });

      Object.keys(visoes).forEach(grade => {
        visoes[grade].sort((v1, v2) => v1.nr_ordem - v2.nr_ordem);
      });

      this.visoes.next(visoes);
      this.visoesLoaded.next(true);
    });
  }

  getVisoes(cd_grade: string): Observable<GridVisoesData> {
    return this.visoes.pipe(
      map(visoes => {
        const visoesGrid = visoes[cd_grade] ?? [];
        const data: GridVisoesData = {
          total: visoesGrid.length,
          visoes: visoesGrid,
          fixas: visoesGrid.filter(v => v.ib_fixado),
          naoFixas: visoesGrid.filter(v => !v.ib_fixado),
        };
        return data;
      }),
    );
  }

  getVisao(cd_grade: string, cd_visao: number) {
    const visoesGrid = this._getVisoesGrid({ cd_grade });
    return visoesGrid.find(v => v.cd_visao == cd_visao);
  }

  findVisaoByName(cd_grade: string, nm_visao: string) {
    const visoesGrid = this._getVisoesGrid({ cd_grade });
    return visoesGrid.find(v => v.nm_visao == nm_visao);
  }

  private _getVisoesGrid(visao: Pick<GridVisao, 'cd_grade'>) {
    return this.visoes.value[visao.cd_grade] ?? [];
  }

  private _putVisoesGrid(visao: GridVisao, visoesGrid: GridVisao[]) {
    const visoesAll = this.visoes.value;
    visoesAll[visao.cd_grade] = visoesGrid;
    this.visoes.next(visoesAll);
  }

  createVisao(visao: GridVisao) {
    visao.nm_visao = visao.nm_visao.trim();
    const visoesGrid = this._getVisoesGrid(visao);
    if (visoesGrid.find(v => v.nm_visao == visao.nm_visao)) {
      const error = new Error(`Já existe visão com nome '${visao.nm_visao}' para esta grid '${visao.cd_grade}'`);
      return throwError(() => error);
    }
    return this.geralApi.createGridVisao(visao).pipe(
      tap(visaoAtual => {
        visaoAtual.state = visao.state;
        visoesGrid.push(visaoAtual);
        this._putVisoesGrid(visaoAtual, visoesGrid);
      }),
    );
  }

  updateVisao(visao: GridVisao) {
    visao.nm_visao = visao.nm_visao.trim();
    visao.ib_fixado = visao.ib_fixado ? 1 : 0;

    const visoesGrid = this._getVisoesGrid(visao);
    if (visoesGrid.find(v => v.nm_visao == visao.nm_visao && v.cd_visao != visao.cd_visao)) {
      const error = new Error(`Já existe outra visão com nome '${visao.nm_visao}' para esta grid '${visao.cd_grade}'`);
      return throwError(() => error);
    }
    return this.geralApi.updateGridVisao(visao).pipe(
      map(() => {
        const visoesGrid = this._getVisoesGrid(visao);
        const index = visoesGrid.findIndex(v => v.cd_visao == visao.cd_visao);
        visoesGrid.splice(index, 1, visao);
        this._putVisoesGrid(visao, visoesGrid);
        return visao;
      }),
    );
  }

  deleteVisao(visao: GridVisao) {
    return this.geralApi.deleteGridVisao(visao).pipe(
      map(() => {
        const visoesGrid = this._getVisoesGrid(visao);
        const index = visoesGrid.findIndex(v => v.cd_visao == visao.cd_visao);
        visoesGrid.splice(index, 1);
        for (let i = index; i < visoesGrid.length; i++) {
          visoesGrid[i].nr_ordem -= 1;
        }
        return visoesGrid;
      }),
      switchMap(visoes => this.reorderVisoes(visoes)),
    );
  }

  reorderVisoes(visoesGrid: GridVisao[]) {
    if (!visoesGrid?.length) {
      return of();
    }
    const visaoSample = visoesGrid[0];
    return this.geralApi.reorderGridVisoes(visoesGrid).pipe(tap(() => this._putVisoesGrid(visaoSample, visoesGrid)));
  }

  applyVisao<TData, TContext>(
    visao: GridVisao,
    grid: AgGridCommon<TData, TContext>,
    sort: string = null,
    filter: FilterModel = null,
  ) {
    // console.log('[Grid] applyVisao', 'visao', visao, 'grid', grid, 'sort', sort);

    if (!grid?.api) {
      console.warn('[Grid] applyVisao to not ready grid:', grid);
      return;
    }

    if (visao) {
      const { cd_grade } = visao;

      const syncMap = this.visaoSync.value;
      syncMap[cd_grade] = visao;
      this.visaoSync.next(syncMap);
      this.lastVisao = visao;

      grid.api.updateGridOptions({ pivotMode: visao.state.pivot });
      grid.api.applyColumnState({ state: visao.state.columns, applyOrder: true });
      grid.api.setFilterModel(filter ?? visao.state.filters);
    }

    if (sort == null) {
      return;
    }

    // console.log('[Grid] applyVisao', 'sort', sort);
    // if (typeof sort == 'string') {
    const sortIndex = parseInt(sort);
    const vCols = grid.api.getAllDisplayedColumns();
    const col = vCols[Math.abs(sortIndex) - 1];
    grid.api.applyColumnState({
      state: [{ colId: col.getColId(), sort: sortIndex > 0 ? 'asc' : 'desc' }],
      defaultState: { sort: null },
    });
    /*
    } else if (sort?.length) {
      grid.api.applyColumnState({
        state: sort,
        defaultState: { sort: null },
      });
    }
    */
  }

  getVisaoInicial(cd_grade: string) {
    // console.log('[Grid] getVisaoInicial', 'cd_grade', cd_grade);

    const visoesGrid = this._getVisoesGrid({ cd_grade });

    let visaoInicial: GridVisao = null;
    visaoInicial = visoesGrid.find(v => v.ib_fixado);
    if (!visaoInicial) {
      visaoInicial = visoesGrid.find(v => v);
    }
    return visaoInicial;
  }

  applyVisaoInicial<TData, TContext>(
    cd_grade: string,
    grid: AgGridCommon<TData, TContext>,
    cd_visao_selected: number = null,
    sort: string = null,
    filter: FilterModel = null,
  ) {
    /*
    if (this.getCurrentVisaoSync(cd_grade)) {
      return;
    }
    */
    let visao: GridVisao = null;
    if (cd_visao_selected) {
      visao = this.getVisao(cd_grade, cd_visao_selected);
    }
    if (!visao) {
      visao = this.getVisaoInicial(cd_grade);
    }

    setTimeout(() => this.applyVisao(visao, grid, sort, filter));
  }

  getCurrentVisao(cd_grade: string) {
    // console.log('[Grid] getCurrentVisao', cd_grade, this.visaoSync.value[cd_grade]);
    return this.visaoSync.value[cd_grade];
  }

  getLastVisao() {
    // console.log('[Grid] getLastVisao', this.lastVisao);
    return this.lastVisao;
  }

  getVisaoSync(cd_grade: string) {
    // console.log('[Grid] getVisaoSync', cd_grade);
    /*
    const syncMap = this.visaoSync.value;
    syncMap[cd_grade] = null;
    this.visaoSync.next(syncMap);
    */
    return this.visaoSync.pipe(map(map => map[cd_grade]));
  }

  setVisaoSync(cd_grade: string, visao: GridVisao) {
    // console.log('[Grid] setVisaoSync', cd_grade, visao);
    const syncMap = this.visaoSync.value;
    syncMap[cd_grade] = visao;
    this.visaoSync.next(syncMap);
  }

  getEncodedFilter<TData, TContext>(grid: AgGridCommon<TData, TContext>): string {
    const model = grid.api.getFilterModel();
    // console.log('[Grid] getEncodedFilter', model);

    if (!Object.keys(model).length) {
      return null;
    }

    const filterString = JSON.stringify(model);
    if (FILTER_MODEL_MAX_LENGTH && filterString.length > FILTER_MODEL_MAX_LENGTH) {
      return null;
    }

    return btoa(filterString);
  }

  getDecodedFilter(encodedString: string): FilterModel {
    // console.log('[Grid] getDecodedFilter', encodedString);
    if (!encodedString) {
      return null;
    }

    const decodedString = atob(encodedString);
    const filterModel: FilterModel = JSON.parse(decodedString);
    return filterModel;
  }

  getEncodedSort<TData, TContext>(grid: AgGridCommon<TData, TContext>): string {
    // console.log('[Grid] getEncodedSort');

    const state = grid.api.getColumnState();
    // console.log('[Grid] state', state);

    const sortState = state
      .filter(col => col.sort != null)
      .map(col => {
        return { colId: col.colId, sort: col.sort, sortIndex: col.sortIndex };
      });
    // console.log('[Grid] sortState', sortState);

    if (!sortState.length) {
      return null;
    }

    const sortString = JSON.stringify(sortState);
    return btoa(sortString);
  }

  getDecodedSort(encodedString: string): SortState[] {
    // console.log('[Grid] getDecodedSort', encodedString);
    if (!encodedString) {
      return null;
    }

    const decodedString = atob(encodedString);
    const sortState: SortState[] = JSON.parse(decodedString);
    return sortState;
  }

  getSimpleSort<TData, TContext>(grid: AgGridCommon<TData, TContext>): number {
    // console.log('[Grid] getSimpleSort');

    const state = grid.api.getColumnState();
    // console.log('[Grid] state', state);

    const vCols = grid.api.getAllDisplayedColumns();
    // console.log('[Grid] vis cols', vCols);
    const vColsIds = vCols.map(col => col.getColId());

    const sortState = state
      .filter(col => col.sort != null)
      .sort((c1, c2) => c1.sortIndex - c2.sortIndex)
      .filter(col => vColsIds.includes(col.colId))
      .map(col => {
        return { colId: col.colId, sort: col.sort, sortIndex: col.sortIndex };
      });
    // console.log('[Grid] sortState', sortState);

    if (!sortState?.length) {
      return null;
    }

    const sortIndex = vCols.findIndex(col => col.getColId() == sortState[0].colId);
    // console.log('[Grid] sort index', sortIndex);

    if (sortState[0].sort == 'asc') {
      return sortIndex + 1;
    } else {
      return -(sortIndex + 1);
    }
  }

  // Workaround to force pivot total to the end
  forcePivotTotalToEnd<TData, TContext>(grid: AgGridCommon<TData, TContext>) {
    // console.log('[Grid] forcePivotTotalToEnd');
    if (!grid?.api) {
      return;
    }

    setTimeout(() => {
      const allCols = grid.api.getAllGridColumns();
      // console.log('[Grid] all cols', allCols);

      const pivotTotals = [];
      const pivotTotalIndexes: number[] = [];
      let lastNonTotalIndex: number = -1;
      for (let idx = 0; idx < allCols.length; idx++) {
        const col = allCols[idx];
        if (col.getColId().startsWith('PivotRowTotal_')) {
          pivotTotals.push(col);
          pivotTotalIndexes.push(idx);
        } else {
          lastNonTotalIndex = idx;
        }
      }
      // console.log('[Grid] pivotTotals', pivotTotals);
      // console.log('[Grid] pivotTotalIndexes', pivotTotalIndexes);
      // console.log('[Grid] lastNonTotalIndex', lastNonTotalIndex);

      if (pivotTotalIndexes?.[0] < lastNonTotalIndex) {
        const newIndex = lastNonTotalIndex - pivotTotalIndexes.length + 1;
        // console.log('[Grid] force moving pivot totals', newIndex);
        grid.api.moveColumns(pivotTotals, newIndex);
      }
    });
  }
}
